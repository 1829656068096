import React, { useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useHistory } from "react-router-dom";

import {
  getEmailOTP,
  getFTC,
  loginFTC,
  updateTokenPreference,
} from "../../api";
import { FTCUpdateToken } from "../../types";
import { supportedFTCNetworks, supportedFTCTokens } from "../../utils";
import BnDialog from "../BnDialog";
import BaseForm from "../forms/_BaseForm";
import Button from "../forms/_Button";
import Dropdown from "../forms/_Dropdown";
import OTPForm from "../forms/OTPForm";
import Loader from "../Loader";

export default function FTCSection() {
  const form = useForm<FieldValues>();
  const history = useHistory();

  const [isOTPSent, setIsOTPSent] = useState(false);
  const [ftcSessionID, setFtcSessionID] = useState("");
  const [isSuccessTokenUpdate, setIsSuccessTokenUpdate] = useState(false);

  const { data: ftc, isLoading } = useQuery("ftc", () => getFTC(), {
    refetchInterval: false,
  });

  const {
    mutate: loginFTCUser,
    isLoading: isLoadingOTP,
    error,
  } = useMutation((otp: string) => loginFTC(ftc!.user_uuid, otp), {
    onSuccess: (ftc) => {
      onOTPSuccess(ftc.ftc_session_id);
    },
  });

  const { mutate: getOTP, isLoading: isSendingOTP } = useMutation(
    (ftcId: string) => getEmailOTP(ftcId),
    {
      onSuccess: () => setIsOTPSent(true),
    },
  );

  const { mutate: updateFTCToken, isLoading: isUpdatingOTP } = useMutation(
    (ftcUpdate: FTCUpdateToken) => updateTokenPreference(ftcUpdate),
    {
      onSuccess: onSuccessUpdateToken,
    },
  );

  if (isLoading || isSendingOTP || isUpdatingOTP) {
    return (
      <div style={{ minHeight: "245px" }}>
        <Loader />
      </div>
    );
  }

  if (ftc?.kyc !== "FULL_USER") {
    history.push("/fiat-payment-kyc");
    return <p></p>;
  }

  function onSubmitTokenPreference() {
    const data = { ...form.getValues(), ftc_session_id: ftcSessionID };
    updateFTCToken(data as FTCUpdateToken);
  }

  function onRequestTokenUpdate() {
    getOTP(ftc!.user_uuid);
  }

  function onOTPSuccess(id: string) {
    setFtcSessionID(id);
    setIsOTPSent(false);
  }

  function onSuccessUpdateToken() {
    setFtcSessionID("");
    setIsSuccessTokenUpdate(true);
  }

  return (
    <div>
      <p className="font-bold text-green-800 dark:text-gray-300">
        Token Preference
      </p>
      <div className="w-1/2 py-5 rounded-md">
        <BaseForm
          form={form}
          onSubmit={
            ftcSessionID ? onSubmitTokenPreference : onRequestTokenUpdate
          }
          buttons={
            <Button
              text={`${ftcSessionID ? "Submit" : "Request"} Token Update`}
              normalText
              type="submit"
              styling="primary-button"
              additionalClasses="w-full capitalize text-16 cursor-pointer font-bold"
            />
          }
        >
          <div className="space-y-6">
            <div>
              Current FTC Network
              <Dropdown
                form={form}
                name="network"
                disabled={!ftcSessionID}
                defaultValue={supportedFTCNetworks.find(
                  (n) => n === ftc.network_preference,
                )}
                options={supportedFTCNetworks.map((n) => ({
                  value: n,
                  label: n,
                }))}
                registerOptions={{ required: ftcSessionID ? true : false }}
              />
            </div>
            <div>
              Current FTC Token
              <Dropdown
                form={form}
                name="token"
                disabled={!ftcSessionID}
                defaultValue={supportedFTCTokens.find(
                  (t) => t === ftc.token_preference,
                )}
                options={supportedFTCTokens.map((t) => ({
                  value: t,
                  label: t,
                }))}
                registerOptions={{ required: ftcSessionID ? true : false }}
              />
            </div>
          </div>
        </BaseForm>
      </div>
      <p className="font-bold text-green-800 dark:text-gray-300 text-14">
        Kindly note that updating your Fiat-to-Crypto (FTC) token preference
        will result in all unpaid FTC invoices being settled in the newly
        selected token.
      </p>
      <BnDialog
        isOpen={isOTPSent}
        title="Please enter the Code you received via email from us. Check your Spam folder, if you can not find the confirmation email."
        centerTitle
        description={
          <OTPForm
            isLoading={isLoadingOTP}
            onSubmit={loginFTCUser}
            error={error}
          />
        }
        onClose={() => setIsOTPSent(false)}
      />
      <BnDialog
        title="Congratulations!"
        titleSize={24}
        centerTitle
        description={<SuccessTokenUpdate />}
        isOpen={isSuccessTokenUpdate}
        onClose={() => setIsSuccessTokenUpdate(false)}
      />
    </div>
  );
}

function SuccessTokenUpdate() {
  const history = useHistory();

  return (
    <div className="space-y-6">
      <p className="text-center text-20 text-green-800 dark:text-gray-300 font-bold ">
        Your Fiat-to-Crypto token preference has been updated successfully.
      </p>
      <div className="flex justify-center">
        <Button
          styling="primary-button"
          text="Overview"
          additionalClasses="mr-10"
          onClick={() => history.push("/auth")}
        />
        <Button
          styling="primary-button"
          text="New Invoice"
          additionalClasses="bg-yellow-50"
          onClick={() => history.push("/new-invoice")}
        />
      </div>
    </div>
  );
}
