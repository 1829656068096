import { Transition } from "@headlessui/react";
import { Big } from "big.js";
import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { FieldValues, UseFormReturn, useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { useHistory, useLocation } from "react-router-dom";

import {
  getContacts,
  getFTC,
  getSupportedCurrencies,
  getTokenFiatValue,
  getWallets,
} from "../../api";
import { ThemeContext, UserContext } from "../../App";
import {
  AvailableFiat,
  ContactResponse,
  Fiat,
  Invoice,
  InvoiceChoice,
  LocationProps,
  Token,
  WalletResponse,
} from "../../types";
import { getFiatSign, getInvoiceItemAmounts, isBusiness } from "../../utils";
import countries from "../../utils/countries.json";
import BnDialog from "../BnDialog";
import InfoPopover from "../InfoPopover";
import Loader from "../Loader";
import { showSnackbar } from "../Snackbar";
import BaseForm from "./_BaseForm";
import Button from "./_Button";
import Dropdown from "./_Dropdown";
import Input from "./_Input";
import TextArea from "./_TextArea";
import ContactForm from "./ContactForm";
import InvoiceItemsTable from "./InvoiceItemsTable";
import WalletForm from "./WalletForm";

type FormProps = {
  onSubmit: (data: Invoice, type: string) => void;
};

const MINIMUM_INVOICE_AMOUNT = 10;

const transitionProps = {
  enter: "transition duration-500 ease-out",
  enterFrom: "transform scale-95 opacity-0",
  enterTo: "transform scale-100 opacity-100",
  leave: "transition duration-75 ease-out",
  leaveFrom: "transform scale-100 opacity-100",
  leaveTo: "transform scale-95 opacity-0",
};

const requiredContactFields = [
  "address_line1",
  "postal_code",
  "city",
  "country",
];

const requiredBankFields = ["bank_account", "iban"];

// TODO(JOEL): This file has to be refactored when time is available, a lot of redundant code that needs clean up...

export default function InvoiceForm({ onSubmit }: FormProps) {
  const theme = useContext(ThemeContext);
  const history = useHistory();
  const { user, setUser } = useContext(UserContext);
  const business = isBusiness(user.signed_email);
  const checkBoxRef = useRef<HTMLInputElement | null>(null);
  const location = useLocation<LocationProps>();
  const isBusinessEdit = !!location.state?.invoice?.id && business;
  const form = useForm<FieldValues>({
    defaultValues: {
      ...location.state?.invoice,
      contact_name: "",
      wallet_name: "",
    },
  });
  const [hoveredContact, setHoveredContact] = useState<
    ContactResponse | undefined
  >(undefined);
  const [isFTCChecked, setIsFTCChecked] = useState(false);
  const [contactDialogOpen, setContactDialogOpen] = useState(false);
  const [newWalletOpen, setNewWalletOpen] = useState(false);
  const [chosenContact, setChosenContact] = useState<ContactResponse | null>(
    location.state?.invoice?.contact || null,
  );
  const [chosenCrypto, setChosenCrypto] = useState<Token | null>(
    location.state?.invoice?.token || null,
  );
  const [chosenWallet, setChosenWallet] = useState<WalletResponse | null>(
    location.state?.invoice?.wallet || null,
  );
  const [invoiceError, setInvoiceError] = useState("");
  const [saveAs, setSaveAs] = useState("");
  const [isOwnerOpen, setIsOwnerOpen] = useState<boolean>(false);
  const [isCustomerOpen, setIsCustomerOpen] = useState<boolean>(false);
  const [isCurrencyOpen, setIsCurrencyOpen] = useState<boolean>(false);
  const [chosenCurrency, setChosenCurrency] = useState<Fiat>(
    location.state?.invoice?.fiat_currency || "USD",
  );
  const [isFiatToCryptoInfo, setIsFiatToCryptoInfo] = useState(false);
  const [isWalletOpen, setIsWalletOpen] = useState<boolean>(false);
  const [isTokenOpen, setIsTokenOpen] = useState<boolean>(false);
  const [isTotalFileSizeExceeded, setIsTotalFileSizeExceeded] =
    useState<boolean>(false);
  const [attachments, setAttachments] = useState<File[]>([]);
  const [invoiceChoice, setInvoiceChoice] = useState<InvoiceChoice>();

  const {
    data: contacts,
    isLoading: contactsLoading,
    refetch: refetchContacts,
  } = useQuery(["contacts", form.watch("contact_name")], () =>
    getContacts({ ordering: "name", search: form.getValues("contact_name") }),
  );

  const {
    data: wallets,
    isLoading: walletsLoading,
    refetch: refetchWallets,
  } = useQuery(["wallets", form.watch("wallet_name")], () =>
    getWallets({ ordering: "name", search: form.getValues("wallet_name") }),
  );

  const { data: supportedTokens, refetch: refetchTokens } = useQuery(
    "tokens",
    () => getSupportedCurrencies(chosenWallet!.network),
    {
      enabled: !!chosenWallet?.network,
    },
  );

  const { data: tokenFiat, refetch: refetchTokenFiat } = useQuery(
    ["token-fiat"],
    () =>
      getTokenFiatValue({
        token: chosenCrypto!.symbol,
        fiat_currency: chosenCurrency,
      }),
    { enabled: !!chosenCrypto?.symbol },
  );
  const { data: ftc } = useQuery("ftc", () => getFTC(), {
    refetchInterval: false,
  });

  const { data: usdtConversion, refetch: refetchUsdtConversion } = useQuery(
    "usdt-conversion",
    () =>
      getTokenFiatValue({
        token: "USDT",
        fiat_currency: chosenCurrency,
      }),
    { refetchInterval: false },
  );

  useEffect(() => {
    if (chosenCurrency && chosenCrypto) {
      refetchTokenFiat();
    }
  }, [chosenCurrency, chosenCrypto, refetchTokenFiat]);

  useEffect(() => {
    if (isBusinessEdit) {
      setInvoiceChoice(form.getValues("kind"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!business) {
      setInvoiceChoice("BASENODE");
    }
  }, [business]);

  const assignUser = useCallback(() => {
    const {
      first_name,
      last_name,
      email,
      company,
      address_line1,
      address_line2,
      city,
      postal_code,
      country,
      bank_name,
      bank_account,
      iban,
      clearing_number,
      swift,
      tax_number,
    } = user;
    form.setValue("owner", {
      first_name,
      last_name,
      email,
      company,
      address_line1,
      address_line2,
      city,
      postal_code,
      country,
      bank_name,
      bank_account,
      iban,
      clearing_number,
      swift,
      tax_number,
    });
    setIsOwnerOpen(false);
  }, [form, user]);

  useEffect(() => {
    if (chosenWallet) {
      refetchTokens();
    }
  }, [chosenWallet, refetchTokens]);

  useEffect(() => {
    assignUser();
  }, [assignUser]);

  useEffect(() => {
    setHoveredContact(undefined);
  }, [chosenContact]);

  useEffect(() => {
    if (supportedTokens && !isFTCChecked) {
      setChosenCrypto(supportedTokens[0]);
    } else if (isFTCChecked && supportedTokens && ftc?.kyc === "FULL_USER") {
      setChosenCrypto(
        supportedTokens?.find(
          (token) => token.symbol === ftc?.token_preference,
        ) || null,
      );
    }
  }, [supportedTokens, isFTCChecked, ftc]);

  useEffect(() => {
    if (isTotalFileSizeExceeded) {
      showSnackbar("Total file size has exceeded 10MB.");
      setIsTotalFileSizeExceeded(false);
    }
  }, [isTotalFileSizeExceeded]);

  useEffect(() => {
    if (chosenCurrency) {
      refetchUsdtConversion();
    }
  }, [chosenCurrency, refetchUsdtConversion]);

  function onContactSubmit(contact?: ContactResponse) {
    setContactDialogOpen(false);
    setChosenContact(contact!);
    refetchContacts();
  }

  function onWalletSubmit() {
    setNewWalletOpen(false);
    refetchWallets();
  }

  function hasContactAddress() {
    return requiredContactFields.every(
      (field) => !!(chosenContact as Record<string, any>)[field],
    );
  }

  function hasOwnerAddress() {
    const owner = form.watch("owner");
    return requiredContactFields.every(
      (field) => !!(owner as Record<string, any>)[field],
    );
  }

  function hasCompleteBankDetails() {
    const owner = form.watch("owner");
    return requiredBankFields.every(
      (field) => !!(owner as Record<string, any>)[field],
    );
  }

  //Somehow the line below causes the "From" box to update properly. Do not remove
  form.watch("owner");

  function isGreaterthanMinimumInvoice(invoice: Invoice) {
    const raw_sub_total = invoice.items
      .map((item) => getInvoiceItemAmounts(item).taxedPrice)
      .reduce((sum, amount) => Big(sum).add(Big(amount)), Big(0));

    const sub_total_in_usdt = raw_sub_total
      .div(usdtConversion?.quote_rate ?? 1)
      .toFixed(4);
    return parseFloat(sub_total_in_usdt) >= MINIMUM_INVOICE_AMOUNT;
  }

  async function getInvoiceErrors() {
    const errors = [];
    if (!chosenContact) errors.push("Choose contact");
    if (
      !form.getValues("owner.email") ||
      (invoiceChoice === "BASENODE" && !form.getValues("owner.first_name"))
    )
      errors.push("Fill user data");
    if (!form.getValues("owner.email")?.match(/^\S+@\S+\.\S+$/))
      errors.push("User email is invalid");
    if (!chosenCrypto) errors.push("Choose currency");
    if (!chosenWallet) errors.push("Choose wallet");
    if (chosenContact && !hasContactAddress())
      errors.push("Chosen contact is missing required data");
    if (invoiceChoice !== "BASENODE" && !hasCompleteBankDetails()) {
      setIsOwnerOpen(true);
      errors.push("Some Bank details are missing");
    }
    return errors;
  }

  async function prepareInvoiceData() {
    const invoiceData: any = {
      ...form.getValues(),
      token: chosenCrypto?.id,
      is_ftc: isFTCChecked,
      kind: invoiceChoice,
      attachments: await Promise.all(
        attachments.map(async (a) => ({
          name: a.name,
          data: await convertToBase64(a),
        })),
      ),
    };
    // Add ids only if user picked them on this session
    if (chosenContact!.id) {
      invoiceData.contact = chosenContact!.id;
    } else {
      delete invoiceData.contact;
    }
    if (chosenWallet!.id) {
      invoiceData.wallet = chosenWallet!.id;
    } else {
      delete invoiceData.wallet;
    }
    invoiceData.fiat_currency = chosenCurrency;

    const { contact_name, wallet_name, ...invoice } = invoiceData;
    setUser({ ...user, ...invoice.owner });
    return invoice as Invoice;
  }

  async function createInvoice() {
    const errors = await getInvoiceErrors();
    if (errors.length > 0) {
      setInvoiceError(`Please fix all required fields: ${errors.join(", ")}`);
      return;
    }
    setInvoiceError("");

    const invoice = await prepareInvoiceData();
    if (!isGreaterthanMinimumInvoice(invoice))
      return showSnackbar(
        "The minimum invoice amount must be the equivalent of 10 USDT.",
      );
    onSubmit(invoice, saveAs);
  }

  async function convertToBase64(file: File) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => resolve(fileReader.result);
      fileReader.onerror = (error) => reject(error);
    });
  }

  function isAllowedTotalFileSize(fileArray: File[]) {
    const totalFileSize = fileArray.reduce(
      (acc: number, current: File) => acc + current.size,
      0,
    );
    return totalFileSize <= 10000000;
  }

  function addAttachement(e: any) {
    if (isAllowedTotalFileSize([...attachments, ...e.target.files])) {
      setIsTotalFileSizeExceeded(false);
      setAttachments((attachments) => [...attachments, ...e.target.files]);
    } else {
      setIsTotalFileSizeExceeded(true);
    }
  }

  function removeAttachment(name: string) {
    setAttachments(
      attachments.filter((attachment) => attachment.name !== name),
    );
  }

  function handleCheckBox(e: any) {
    setIsFTCChecked(e.target.checked);

    if ((!ftc || ftc.kyc !== "FULL_USER") && e.target.checked) {
      setIsFiatToCryptoInfo(true);
    }
    if (e.target.checked) {
      setChosenCurrency("EUR");

      setChosenWallet(
        wallets?.find((wallet) => wallet.id === ftc?.wallet) || null,
      );
    }
  }

  function closeFiatToCryptoInfo() {
    setIsFiatToCryptoInfo(false);
    setIsFTCChecked(false);
    if (checkBoxRef.current) {
      checkBoxRef.current.checked = false;
    }
  }

  return (
    <>
      <BaseForm
        form={form}
        onSubmit={createInvoice}
        buttons={
          <div className="flex mx-auto mt-8">
            <Button
              text="SAVE DRAFT"
              type="submit"
              styling="transparent-button"
              additionalClasses="w-1/4 md:w-40 font-bold border border-green-800 mr-4 dark:border-gray-450 text-center"
              onClick={() => {
                setSaveAs("DRAFT");
                form.setValue("download_only", false);
              }}
            />

            <Button
              text="Download PDF"
              type="submit"
              styling="transparent-button"
              normalText
              additionalClasses="w-1/4 md:w-40 font-bold text-12 md:text-14 normal-case border border-green-800 mr-4 dark:border-gray-450 break-words p-0"
              onClick={() => {
                setSaveAs("DUE");
                form.setValue("download_only", true);
              }}
            />

            <Button
              text="SEND"
              type="submit"
              styling="always-green-button"
              additionalClasses="w-1/4 md:w-40 font-bold text-center"
              onClick={() => {
                setSaveAs("DUE");
                form.setValue("download_only", false);
              }}
            />
          </div>
        }
      >
        <p className="font-semibold mb-2">Let&apos;s create your invoice!</p>
        <span className="font-bold w-full md:w-1/3 text-20 mb-2 md:mb-10 border rounded-md border-gray-450 dark:bg-gray-950 flex">
          <Input
            form={form}
            name="name"
            placeholder="Invoice name*"
            mainClasses="focus:outline-none p-2 text-16 rounded-md bg-white dark:bg-gray-950"
            registerOptions={{ required: true }}
          />
          <img
            src={`/icons/${theme}/actions/edit.svg`}
            alt="edit"
            className="w-5 mx-6"
          />
        </span>
        {invoiceChoice && invoiceChoice !== "BASENODE" && (
          <div className="space-y-8 mb-8 mr-10">
            <Input
              form={form}
              name="number"
              placeholder="Invoice No.*"
              mainClasses="focus:outline-none p-2 w-full md:w-1/2 text-16 rounded-md border border-gray-600 bg-white dark:bg-gray-950"
              registerOptions={{ required: true }}
            />
            <Input
              form={form}
              name="vendor"
              placeholder="Vendor No."
              mainClasses="focus:outline-none p-2 w-full md:w-1/2 text-16 rounded-md border border-gray-600 bg-white dark:bg-gray-950"
            />
            <Input
              form={form}
              name="project_name"
              placeholder="Project Name*"
              mainClasses="focus:outline-none p-2 w-full md:w-1/2 text-16 rounded-md border border-gray-600 bg-white dark:bg-gray-950"
              registerOptions={{ required: true }}
            />
            <Input
              form={form}
              name="covering_period"
              placeholder="Covering Period*"
              mainClasses="focus:outline-none p-2 w-full md:w-1/2 text-16 rounded-md border border-gray-600 bg-white dark:bg-gray-950"
              registerOptions={{ required: true }}
            />
          </div>
        )}
        <div className="grid md:grid-cols-2 grid-cols-1 gap-x-10">
          <div className="relative z-10 h-60">
            <p className="font-semibold mb-4 dark:text-gray-300 md:mt-0 mt-10">
              Bill to *
            </p>
            <div className="absolute border bg-white border-gray-450 dark:bg-gray-950 rounded-md focus:outline-none w-full">
              <div className="h-48 flex justify-between items-start px-10 py-2">
                {chosenContact ? (
                  <div className="flex">
                    <div className="avatar mr-4">
                      {chosenContact.first_name[0].toUpperCase()}
                      {chosenContact.last_name[0].toUpperCase()}
                    </div>
                    <div className="flex flex-col text-left">
                      <p className="font-semibold text-18 my-2">
                        {chosenContact.first_name} {chosenContact.last_name}{" "}
                        {!form.watch("owner.company") && (
                          <span className="text-12 text-gray-800-t-80 ml-4 dark:text-gray-450">
                            {chosenContact.company}
                          </span>
                        )}
                      </p>
                      {form.watch("owner.company") && (
                        <p>{chosenContact.company}</p>
                      )}
                      <p className="text-16 my-4">{chosenContact.email}</p>
                      {hasContactAddress() ? (
                        <p className="text-16">
                          {chosenContact.address_line1}
                          {chosenContact.address_line2
                            ? ` ${chosenContact.address_line2}, `
                            : ", "}
                          {chosenContact.postal_code}
                          <br />
                          {chosenContact.city}, {chosenContact.country}
                        </p>
                      ) : (
                        <p className="flex text-16 text-gray-800">
                          <img
                            src="/icons/warning_circle.svg"
                            alt="warning"
                            className="mr-2"
                          />{" "}
                          Chosen contact doesn&apos;t have all required address
                          data
                        </p>
                      )}
                    </div>
                  </div>
                ) : (
                  <p className="flex text-16 text-gray-800 dark:text-gray-300">
                    <img
                      src="/icons/warning_circle.svg"
                      alt="warning"
                      className="mr-2"
                    />{" "}
                    Find or add customer
                  </p>
                )}
                <img
                  src={`/icons/${theme}/arrows/${
                    isCustomerOpen ? "expand_up" : "expand_down_light"
                  }.svg`}
                  alt="dropdown"
                  className="h-8 cursor-pointer"
                  onClick={() => setIsCustomerOpen((prev) => !prev)}
                />
              </div>
              {isCustomerOpen && (
                <div className="py-8 mx-8 border-t-2">
                  <div
                    className="flex cursor-pointer mb-3 w-max p-1 rounded-md hover:bg-opacity-50 hover:bg-gray-100"
                    onClick={() => setContactDialogOpen(true)}
                  >
                    <img
                      src={`/icons/${theme}/add_contact.svg`}
                      alt="add"
                      className="mr-2"
                    />
                    <p className="text-16 whitespace-nowrap">Add new contact</p>
                  </div>
                  <div
                    className={`flex flex-col overflow-y-auto -mr-8 ${
                      theme === "dark" ? "custom-scroll-dark" : "custom-scroll"
                    }`}
                    style={{ maxHeight: "230px" }}
                  >
                    <div className="relative mb-2 mr-8">
                      <img
                        src={`/icons/${theme}/green_loupe.svg`}
                        alt="search"
                        className="absolute z-50 top-2 mt-2 ml-2 w-4"
                      />
                      <Input
                        form={form}
                        name="contact_name"
                        placeholder="Search existing contact"
                        mainClasses="focus:outline-none pl-8 bg-gray-100 py-2 rounded-xl text-14"
                      />
                    </div>
                    {contactsLoading && <Loader />}
                    {contacts?.length === 0 && !contactsLoading && (
                      <p className="text-12">No contacts found</p>
                    )}
                    {contacts &&
                      contacts.map((c) => (
                        <div
                          key={c.id}
                          className="my-2 cursor-pointer flex justify-between"
                          onMouseEnter={() => setHoveredContact(c)}
                        >
                          <div
                            className="w-full"
                            onClick={() => {
                              setChosenContact(c);
                              setIsCustomerOpen(false);
                            }}
                          >
                            <div className="flex items-center w-full">
                              <div className="avatar mr-4">
                                {c.first_name[0].toUpperCase()}
                                {c.last_name[0].toUpperCase()}
                              </div>
                              <p className="font-bold text-14 text-gray-800 mr-4 dark:text-gray-300">
                                {c.first_name} {c.last_name}
                              </p>
                              <p className="text-gray-800-t-80 text-12 dark:text-gray-300">
                                {c.company}
                              </p>
                            </div>
                          </div>
                          <div
                            className="mr-10 cursor-pointer"
                            onClick={() => setContactDialogOpen(true)}
                          >
                            <img
                              src={`/icons/${theme}/actions/edit.svg`}
                              className={
                                hoveredContact?.id === c.id
                                  ? "inline-block"
                                  : "hidden"
                              }
                              alt="edit"
                            />
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div style={{ zIndex: 9 }} className="relative h-60">
            <p className="font-semibold mb-4 dark:text-gray-300 md:mt-0 mt-10">
              From *
            </p>
            <div className="absolute border bg-white border-gray-450 dark:bg-gray-950 rounded-md focus:outline-none w-full">
              <div className="h-48 flex justify-between items-start px-10 py-2">
                {(invoiceChoice === "BASENODE" &&
                  !form.watch("owner.first_name")) ||
                !form.watch("owner.email") ? (
                  <p className="flex text-16 text-gray-800 dark:text-gray-300">
                    <img
                      src="/icons/warning_circle.svg"
                      alt="warning"
                      className="mr-2"
                    />{" "}
                    Add my information
                  </p>
                ) : (
                  <div className="text-left text-gray-800 dark:text-gray-300 w-full">
                    <p className="semi-bold my-2 text-18 break-words">
                      {form.watch("owner.first_name")}{" "}
                      {form.watch("owner.last_name")}
                    </p>
                    <p className="text-16 break-words">
                      {form.watch("owner.company")}
                    </p>
                    <p
                      className={`text-16 ${
                        form.watch("owner.company") ? "my-4" : "mb-4 mt-6"
                      }
                         break-words`}
                    >
                      {form.watch("owner.email")}
                    </p>
                    {hasOwnerAddress() ? (
                      <p className="text-16">
                        {form.watch("owner.address_line1")}
                        {form.watch("owner.address_line2")
                          ? ` ${form.watch("owner.address_line2")}, `
                          : ", "}
                        {form.watch("owner.postal_code")}
                        <br />
                        {form.watch("owner.city")},{" "}
                        {form.watch("owner.country")}
                      </p>
                    ) : (
                      <p className="flex text-16 text-gray-800">
                        <img
                          src="/icons/warning_circle.svg"
                          alt="warning"
                          className="mr-2"
                        />{" "}
                        Please provide all the required address data
                      </p>
                    )}
                  </div>
                )}
                <img
                  src={`/icons/${theme}/arrows/${
                    isOwnerOpen ? "expand_up" : "expand_down_light"
                  }.svg`}
                  alt="dropdown"
                  className="h-8 cursor-pointer"
                  onClick={() => setIsOwnerOpen((prev: boolean) => !prev)}
                />
              </div>
              {isOwnerOpen && (
                <div className="py-8 mx-8 border-t-2">
                  <UserForm
                    form={form}
                    namePrefix="owner"
                    invoiceChoice={invoiceChoice}
                  />
                  {user.first_name && (
                    <>
                      <p className="text-16 text-green-800 my-4 pb-2 dark:text-gray-300">
                        Previously
                      </p>
                      <div onClick={assignUser}>
                        <div className="flex text-left cursor-pointer">
                          <div className="avatar">
                            {user.first_name[0]?.toUpperCase()}
                            {user.last_name[0]?.toUpperCase()}
                          </div>
                          <div className="flex flex-col text-14 text-gray-800 ml-4 dark:text-gray-300">
                            <p className="font-bold">
                              {user.first_name} {user.last_name}
                            </p>
                            <p>{user.email}</p>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="relative h-28">
          <div className="absolute top-16 md:top-4 left-0 md:left-1/2 dark:text-gray-300 text-green-800 w-full md:w-1/2 font-semibold text-16 mt-4">
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                ref={checkBoxRef}
                className="h-6 w-6 checked:bg-green-800 cursor-pointer"
                onChange={(e) => handleCheckBox(e)}
              />
              <span>Fiat-to-Crypto</span>
            </label>
            <p className="mt-4">
              Allow your client to pay using SEPA / IBAN and automatically
              convert the payment to the selected token and send it to your
              wallet. Only for supported currencies. A flat fee of 1.5% of the
              invoice amount will be deducted.
            </p>
          </div>
          <div className="flex mt-80 md:mt-10 mb-2">
            <p className="font-semibold mr-2">
              What is your invoice currency? *
            </p>
            <div className="relative">
              <InfoPopover
                text="Your invoice currency computes the amount of the cryptocurrency you receive at the time of payment."
                additionalPopoverClasses="left-10 -bottom-8"
              />
            </div>
          </div>
          <div
            style={{
              zIndex: 5,
            }}
            className={`lg:w-1/5 md:w-1/3 w-2/3 px-6 py-2 absolute border bg-white border-gray-450 dark:bg-gray-950 rounded-md focus:outline-none ${
              isFTCChecked ? "bg-gray-450-t-58 dark:bg-gray-600" : ""
            }`}
          >
            <div className="flex justify-between">
              <p>
                <span className="font-bold text-green-300 mr-2">
                  {getFiatSign(chosenCurrency)}
                </span>
                {chosenCurrency}
              </p>
              <img
                src={`/icons/${theme}/arrows/${
                  isCurrencyOpen && !isFTCChecked
                    ? "expand_up"
                    : "expand_down_light"
                }.svg`}
                alt="dropdown"
                className="w-5 cursor-pointer"
                onClick={() => setIsCurrencyOpen((prev) => !prev)}
              />
            </div>
            {isCurrencyOpen && !isFTCChecked && (
              <div>
                {AvailableFiat.map((fiat) => (
                  <p
                    key={fiat}
                    className="mt-2 cursor-pointer"
                    onClick={() => {
                      setChosenCurrency(fiat);
                      setIsCurrencyOpen(false);
                    }}
                  >
                    <span className="font-bold text-green-300 mr-2">
                      {getFiatSign(fiat)}
                    </span>
                    {fiat}
                  </p>
                ))}
              </div>
            )}
          </div>
        </div>
        <div className="relative mt-80 md:mt-10 w-full md:w-100 h-28">
          <div className="flex mb-2">
            <p className="font-semibold mr-2">
              Which wallet do you want to receive funds? *
            </p>
            <div className="relative">
              <InfoPopover
                text="The wallet on which you will receive your cryptocurrency payment."
                additionalPopoverClasses="left-18 -bottom-8"
              />
            </div>
          </div>
          <div
            style={{
              zIndex: 3,
            }}
            className={`absolute px-4 w-full bg-white rounded-md border border-gray-450 dark:bg-gray-950 ${
              isFTCChecked ? "bg-gray-450-t-58 dark:bg-gray-600" : ""
            }`}
          >
            <div className="h-14 flex justify-between items-center">
              <div className="flex">
                <img
                  src={
                    chosenWallet
                      ? `/icons/network/${chosenWallet.network.toLowerCase()}.svg`
                      : "/icons/warning_circle.svg"
                  }
                  alt="icon"
                  className="mr-4 w-5"
                />
                <p className="text-left">
                  {chosenWallet ? chosenWallet.name : "Find or add wallet"}
                </p>
              </div>
              <img
                src={`/icons/${theme}/arrows/${
                  isWalletOpen && !isFTCChecked
                    ? "expand_up"
                    : "expand_down_light"
                }.svg`}
                alt="dropdown"
                className="h-8 cursor-pointer"
                onClick={() => setIsWalletOpen((prev: boolean) => !prev)}
              />
            </div>
            {isWalletOpen && !isFTCChecked && (
              <div>
                <div
                  className="flex cursor-pointer mb-3 w-max p-1 rounded-md hover:bg-opacity-50 hover:bg-gray-100"
                  onClick={() => setNewWalletOpen(true)}
                >
                  <img
                    src={`/icons/${theme}/plus.svg`}
                    alt="add"
                    className="mr-2"
                  />
                  <p className="text-16">Add new wallet</p>
                </div>
                <div
                  className={`flex flex-col overflow-y-auto -mr-8 ${
                    theme === "dark" ? "custom-scroll-dark" : "custom-scroll"
                  }`}
                  style={{ maxHeight: "280px" }}
                >
                  <div className="relative mb-2 mr-10">
                    <img
                      src={`/icons/${theme}/green_loupe.svg`}
                      alt="search"
                      className="absolute z-50 top-2 mt-2 ml-2 w-4"
                    />
                    <Input
                      form={form}
                      name="wallet_name"
                      placeholder="Search wallet name"
                      mainClasses="focus:outline-none pl-8 bg-gray-100 py-2 rounded-xl text-14"
                    />
                  </div>
                  {walletsLoading && <Loader />}
                  {wallets?.length === 0 && !walletsLoading && (
                    <p className="text-12">No wallets found</p>
                  )}
                  {wallets &&
                    wallets.map((w) => (
                      <div
                        key={w.id}
                        className="my-2 p-1 cursor-pointer mr-10"
                        onClick={() => {
                          setChosenWallet(w);
                          setIsWalletOpen(false);
                        }}
                      >
                        <div className="flex items-center w-full">
                          <img
                            src={`/icons/network/${w.network.toLowerCase()}.svg`}
                            alt="network"
                            className="mr-2 w-5"
                          />
                          <p className="text-14 text-left text-gray-800 mr-4 dark:text-gray-300">
                            {w.name}
                          </p>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            )}
          </div>
        </div>
        {chosenWallet && supportedTokens && (
          <Transition
            as={Fragment}
            show={!!supportedTokens}
            {...transitionProps}
          >
            <div className="flex justify-between">
              <div className="relative h-28">
                <div className="flex mt-5 mb-2">
                  <p className="font-semibold mr-2">
                    How do you want to receive funds? *
                  </p>
                  <div className="relative">
                    <InfoPopover
                      text="The cryptocurrency that you will receive as payment."
                      additionalPopoverClasses="left-10 -bottom-8"
                    />
                  </div>
                </div>
                <div
                  style={{ zIndex: 2 }}
                  className={`w-1/2 px-6 py-2 absolute border bg-white border-gray-450 dark:bg-gray-950 rounded-md focus:outline-none ${
                    isFTCChecked ? "bg-gray-450-t-58 dark:bg-gray-600" : ""
                  }`}
                >
                  <div className="flex justify-between">
                    <div className="flex">
                      <img
                        src={chosenCrypto?.image_url}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.src = "/icons/logo_small.svg";
                        }}
                        alt={chosenCrypto?.symbol}
                        className="w-4 h-4 mt-1 mr-3"
                      />
                      <p>{chosenCrypto?.symbol}</p>
                    </div>
                    <img
                      className="w-5 relative left-2 cursor-pointer"
                      src={
                        isTokenOpen && !isFTCChecked
                          ? `/icons/${theme}/checkbox/check.svg`
                          : `/icons/${theme}/arrows/expand_down_light.svg`
                      }
                      alt="dropdown"
                      onClick={() => setIsTokenOpen((prev) => !prev)}
                    />
                  </div>
                  {isTokenOpen && !isFTCChecked && (
                    <div
                      className={`overflow-y-auto overflow-x-hidden max-h-60 ${
                        theme === "dark"
                          ? "custom-scroll-dark"
                          : "custom-scroll"
                      }`}
                    >
                      {supportedTokens.map((token) => (
                        <div
                          className="flex mt-2 cursor-pointer"
                          key={token.id}
                          onClick={() => {
                            setChosenCrypto(token);
                            setIsTokenOpen(false);
                          }}
                        >
                          <img
                            src={token.image_url}
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null;
                              currentTarget.src = "/icons/logo_small.svg";
                            }}
                            alt={token.symbol}
                            className="w-4 h-4 mt-1 mr-3"
                          />
                          <p>{token.symbol}</p>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                {isFTCChecked && (
                  <div className="flex mt-14">
                    <Button
                      text="Update Token"
                      onClick={() =>
                        history.push("/settings?section=fiat-to-crypto")
                      }
                      type="button"
                      normalText
                      styling="primary-button"
                      additionalClasses="capitalize font-bold text-12 px-2 py-1 mr-2 dark:bg-green-800"
                    />
                    <div className="relative">
                      <InfoPopover
                        text="Click to update your Fiat-to-Crypto token preference"
                        additionalPopoverClasses="left-10 -bottom-8"
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Transition>
        )}

        <p className="font-semibold mt-10">List your items / services</p>
        <InvoiceItemsTable
          form={form}
          chosenCrypto={chosenCrypto}
          chosenCurrency={chosenCurrency}
          tokenFiat={tokenFiat?.quote_rate}
        />

        <p className="text-18 font-semibold mt-10 mb-4">Message to customer</p>
        <TextArea
          form={form}
          name="message"
          placeholder="Write a message"
          mainClasses={`py-3 px-5 h-32 bg-white border border-green-800 rounded-md focus:outline-none dark:bg-gray-900 dark:border-gray-450-t-58 ${
            theme === "dark" ? "custom-scroll-dark" : "custom-scroll"
          }`}
        />
        <PreviewAttachements
          files={attachments}
          removeAttachment={removeAttachment}
        />
        <label className="flex text-14 w-24 cursor-pointer">
          <img src="/icons/attach.svg" alt="attachment" className="mr-2" />
          <p className="mt-0.5">Attach File</p>
          <input
            type="file"
            className="hidden"
            onChange={addAttachement}
            accept="image/*,.pdf"
          />
        </label>

        {/* Placeholder - Decision not yet concluded by product owners...  */}
        <div className="flex justify-between items-center w-full md:w-100 my-8 rounded-md border border-gray-450 px-4 h-14 bg-gray-200 dark:bg-gray-950">
          <p className="text-16 text-gray-800 dark:text-gray-300">
            Does not reoccur
          </p>
          <img
            src={`/icons/${theme}/arrows/expand_down_light.svg`}
            alt="dropdown"
            className="h-8"
          />
        </div>

        <div className="flex items-center">
          <p className="font-semibold lg:w-1/5 w-1/3">Invoice Issued</p>
          <Input
            form={form}
            name="issued_at"
            placeholder="Issued Date*"
            mainClasses="bg-white w-4/5 lg:w-1/4 px-4 h-12 rounded-md border border-gray-600 focus:outline-none dark:bg-gray-900"
            type="date"
            registerOptions={{ required: true }}
          />
        </div>
        <div className="flex items-center">
          <p className="font-semibold w-32">Due Date</p>
          <Input
            form={form}
            name="expires_at"
            disabled={!form.watch("issued_at")}
            min={form.watch("issued_at")}
            placeholder="Due Date*"
            mainClasses={`bg-white w-4/5 lg:w-1/4 px-4 h-12 rounded-md border border-gray-600 focus:outline-none dark:bg-gray-900 ${
              !form.watch("issued_at") && "bg-gray-450-t-58 dark:bg-gray-600"
            }`}
            type="date"
            registerOptions={{
              required: true,
              validate: {
                positive: (value) =>
                  value > form.watch("issued_at") ||
                  "Must be Greater than Invoice Issued.",
              },
            }}
          />
        </div>

        <p className="mt-6 text-12 font-semibold text-center text-red-500">
          {invoiceError}
        </p>
      </BaseForm>
      <BnDialog
        isOpen={contactDialogOpen}
        title="Contact information"
        centerTitle
        description={
          <ContactForm
            onSubmit={onContactSubmit}
            defaultValues={hoveredContact}
          />
        }
        onClose={() => {
          setContactDialogOpen(false);
          setHoveredContact(undefined);
        }}
      />
      <BnDialog
        isOpen={newWalletOpen}
        title="Add Wallet"
        centerTitle
        description={<WalletForm onSubmit={onWalletSubmit} />}
        onClose={() => setNewWalletOpen(false)}
      />
      <BnDialog
        isOpen={isFiatToCryptoInfo}
        title=""
        maxWidth="max-w-xl"
        description={<FiatToCryptoInfo handleNo={closeFiatToCryptoInfo} />}
        onClose={closeFiatToCryptoInfo}
      />
      {!!business && (
        <BnDialog
          isOpen={!invoiceChoice}
          title="Choose your invoice template"
          maxWidth="max-w-5xl"
          centerTitle
          description={
            <BusinessInvoiceChoice selectChoice={setInvoiceChoice} />
          }
          onClose={() => setInvoiceChoice("BASENODE")}
        />
      )}
    </>
  );
}

type UserFormProps = {
  form: UseFormReturn;
  namePrefix: string;
  invoiceChoice?: InvoiceChoice;
};

function UserForm({ form, namePrefix, invoiceChoice }: UserFormProps) {
  const prefix = namePrefix ? namePrefix + "." : "";

  return (
    <div className="relative pb-6">
      <div className="grid grid-cols-2 gap-x-4 gap-y-2">
        <div>
          <Input
            form={form}
            name={`${prefix}first_name`}
            placeholder={`First name${
              invoiceChoice === "BASENODE" ? "*" : ""
            } `}
            registerOptions={{
              required: invoiceChoice === "BASENODE" ? true : false,
            }}
          />
        </div>
        <div>
          <Input
            form={form}
            name={`${prefix}last_name`}
            placeholder={`Last name${invoiceChoice === "BASENODE" ? "*" : ""} `}
            registerOptions={{
              required: invoiceChoice === "BASENODE" ? true : false,
            }}
          />
        </div>
      </div>
      <div className="mt-2">
        <Input
          form={form}
          name={`${prefix}email`}
          type="email"
          placeholder="Email*"
          registerOptions={{ required: true }}
        />
      </div>
      <div className="mt-2">
        <Input
          form={form}
          name={`${prefix}company`}
          placeholder="Company (optional)"
        />
      </div>
      {invoiceChoice && invoiceChoice !== "BASENODE" && (
        <div className="space-y-2">
          <Input
            form={form}
            name={`${prefix}bank_name`}
            placeholder="Bank Name"
          />
          <Input
            form={form}
            name={`${prefix}bank_account`}
            placeholder="Account Number*"
            registerOptions={{ required: true }}
          />
          <Input
            form={form}
            name={`${prefix}clearing_number`}
            placeholder="Clearing Number"
          />
          <Input form={form} name={`${prefix}swift`} placeholder="SWIFT" />
          <Input
            form={form}
            name={`${prefix}iban`}
            placeholder="IBAN*"
            registerOptions={{ required: true }}
          />
        </div>
      )}
      <div className="mt-2">
        <Input
          form={form}
          name={`${prefix}address_line1`}
          placeholder="Address line 1*"
          registerOptions={{ required: true }}
        />
      </div>
      <div className="mt-2">
        <Input
          form={form}
          name={`${prefix}address_line2`}
          placeholder="Address line 2"
        />
      </div>
      <div className="grid grid-cols-2 gap-x-4">
        <div>
          <Input
            form={form}
            name={`${prefix}city`}
            placeholder="City*"
            registerOptions={{ required: true }}
          />
        </div>
        <div>
          <Input
            form={form}
            name={`${prefix}postal_code`}
            placeholder="Postal code*"
            registerOptions={{ required: true }}
          />
        </div>
        <div>
          <Dropdown
            form={form}
            name={`${prefix}country`}
            placeholder="Country*"
            registerOptions={{ required: true }}
            options={countries.map((c) => ({
              label: c,
              value: c,
            }))}
          />
        </div>
        <div>
          <Input
            form={form}
            name={`${prefix}tax_number`}
            placeholder="VAT number"
          />
        </div>
      </div>
    </div>
  );
}
type PreviewAttachmentsProps = {
  files: File[];
  removeAttachment: (name: string) => void;
};

function PreviewAttachements({
  files,
  removeAttachment,
}: PreviewAttachmentsProps) {
  const theme = useContext(ThemeContext);
  return (
    <div className="flex flex-wrap my-1">
      {files.map((file) => (
        <div
          key={file.name}
          className="text-12 flex justify-between font-semibold text-gray-800 dark:text-gray-300 rounded-lg m-1 p-2 bg-gray-100 dark:bg-gray-950"
        >
          <p>
            <span className="text-green-800 text-14 font-bold mr-2">
              {file.name}
            </span>
            ({file.size / 1000}K)
          </p>
          <img
            src={`/icons/${theme}/close.svg`}
            alt="close"
            className="cursor-pointer w-3 h-3 mt-1 ml-20"
            onClick={() => removeAttachment(file.name)}
          />
        </div>
      ))}
    </div>
  );
}

type FiatToCryptoInfoProps = {
  handleNo: () => void;
};

function FiatToCryptoInfo({ handleNo }: FiatToCryptoInfoProps) {
  const history = useHistory();

  function handleYes() {
    history.push("fiat-payment-kyc");
  }

  return (
    <div className="space-y-6 text-center text-20 text-green-900">
      <p>
        Do you want to start the Setup of Fiat-to-Crypto invoicing? Unsaved
        progress of your current invoice will be lost.
      </p>
      <div className="flex justify-center">
        <Button
          text="YES"
          styling="always-green-button"
          normalText
          additionalClasses="capitalize text-14 font-bold mr-4"
          onClick={handleYes}
        />
        <Button
          styling="primary-button"
          text="N0"
          additionalClasses="bg-yellow-50 font-bold px-18"
          onClick={handleNo}
        />
      </div>
    </div>
  );
}

type BusinessInvoiceChoiceProps = {
  selectChoice: (s: InvoiceChoice) => void;
};

function BusinessInvoiceChoice({ selectChoice }: BusinessInvoiceChoiceProps) {
  return (
    <>
      <div className="flex justify-around text-center text-20 text-green-800 dark:text-gray-300">
        <div
          onClick={() => selectChoice("BASENODE")}
          className="cursor-pointer"
        >
          <div className="border-2 rounded-md mb-4">
            <img src="/icons/invoices/basenode.svg" alt="basenode template" />
          </div>
          <p>basenode.io standard</p>
        </div>
        <div onClick={() => selectChoice("TAHOTM")} className="cursor-pointer">
          <div className="border-2 rounded-md mb-4">
            <img
              className="w-60"
              src="/icons/invoices/tahotm.png"
              alt="tahotm template"
            />
          </div>
          <p>TAH - OTM</p>
        </div>
        <div
          onClick={() => selectChoice("ADVISORY")}
          className="cursor-pointer"
        >
          <div className="border-2 rounded-md mb-4">
            <img src="/icons/invoices/advisory.svg" alt="advisory template" />
          </div>
          <p>Advisory House</p>
        </div>
      </div>
      <button></button>
    </>
  );
}
