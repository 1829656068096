import React, { useRef, useState } from "react";

import Button from "../../components/forms/_Button";
import Loader from "../../components/Loader";

type OTPFormProps = {
  isLoading: boolean;
  error: any;
  onSubmit: (otp: string) => void;
};

export default function OTPForm({ isLoading, onSubmit, error }: OTPFormProps) {
  const [otp, setOTP] = useState<(number | null)[]>([
    null,
    null,
    null,
    null,
    null,
    null,
  ]);

  const inputRefs = useRef<HTMLInputElement[]>([]);

  function handleOTPChange(
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) {
    const enteredDigit = parseInt(e.target.value, 10);
    const updatedOTP = [...otp];

    // Check if the entered value is a valid number (0-9) or empty string
    if (!isNaN(enteredDigit) || e.target.value === "") {
      updatedOTP[index] = isNaN(enteredDigit) ? null : enteredDigit;
      setOTP(updatedOTP);

      if (index < otp.length - 1 && e.target.value !== "") {
        inputRefs.current[index + 1].focus();
      }
    }
  }

  function handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    const isEveryDigitNumber = otp.every((digit) => typeof digit === "number");
    if (!isEveryDigitNumber) return;

    const enteredOTP = otp.join("");
    onSubmit(enteredOTP);
  }

  return (
    <div className="flex justify-center items-center">
      {!isLoading ? (
        <form onSubmit={handleSubmit} className="text-center">
          <div className="flex justify-center space-x-4 my-5">
            {otp.map((digit, index) => (
              <input
                key={index}
                type="text"
                minLength={1}
                maxLength={1}
                className="w-10 h-10 text-20 text-center border-2 rounded focus:outline-none border-green-800 focus:border-green-300 focus:ring-green-300 focus:ring"
                value={digit !== null ? digit : ""}
                onChange={(e) => handleOTPChange(e, index)}
                ref={(input) => {
                  if (input) {
                    inputRefs.current[index] = input;
                  }
                }}
              />
            ))}
          </div>
          <Button text="Verify" styling="primary-button" type="submit" />
          {error && (
            <p className="text-12 text-red-500 font-semibold">
              {((error as any).response?.data?.non_field_errors ?? []).join(
                " ",
              )}
            </p>
          )}
        </form>
      ) : (
        <Loader />
      )}
    </div>
  );
}
