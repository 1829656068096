import React from "react";

// Consts
export const AvailableFiat = [
  "USD",
  "CAD",
  "EUR",
  "SGD",
  "INR",
  "JPY",
  "VND",
  "CNY",
  "KRW",
  "CHF",
  "RUB",
  "TRY",
  "ETH",
  "BNB",
  "BTC",
] as const;

export type Fiat = typeof AvailableFiat[number];

export const AvailableExchange = ["BINANCE"] as const;

export type Exchange = typeof AvailableExchange[number];

export const AvailableNetworks = [
  "ETHEREUM",
  "BITCOIN",
  "POLYGON",
  "FANTOM",
  "BINANCE_SMART_CHAIN",
  "RONIN",
] as const;

export type Network = typeof AvailableNetworks[number];

export const AvailableKYC = [
  "KYC_NEEDED",
  "PENDING_KYC_DATA",
  "KYC_PENDING",
  "SOFT_KYC_FAILED",
  "HARD_KYC_FAILED",
  "FULL_USER",
] as const;

export type KYC = typeof AvailableKYC[number];

// TODO: Consider removing this....
export const AvailableCrypto = [
  "BTC",
  "ETH",
  "DAI",
  "WBTC",
  "WETH",
  "USDT",
  "USDC",
  "COMP",
  "AAVE",
  "MKR",
  "LINK",
  "UNI",
  "MATIC",
  "YFI",
  "BAT",
  "GRT",
  "SUSHI",
  "CRV",
  "BNT",
  "REN",
  "ZRX",
  "ENJ",
  "UMA",
  "LRC",
  "SAND",
  "ANT",
  "OCEAN",
  "RSR",
  "GNO",
  "OGN",
  "BAND",
  "BNB",
  "BUSD",
  "CAKE",
  "BTCB",
] as const;

export type Crypto = typeof AvailableCrypto[number];

export const AvailableColors = [
  "RED",
  "ORANGE",
  "YELLOW",
  "GREEN",
  "BLUE",
  "PINK",
  "PURPLE",
] as const;

export type Color = typeof AvailableColors[number];
export const InvoiceTypes = [
  "DUE",
  "DRAFT",
  "EXPIRED",
  "PAID",
  "PROCESSING",
] as const;
export const AvailableInvoiceChoice = [
  "BASENODE",
  "ADVISORY",
  "TAHOTM",
] as const;
export type InvoiceChoice = typeof AvailableInvoiceChoice[number];

export const DocumentTypes = [
  "TRANSACTION_REPORT",
  "QUICKBOOKS_REPORT",
  "WALLET_REPORT",
  "PORTFOLIO_PERFORMANCE_REPORT",
  "INCOME_STATEMENT",
  "BALANCES_REPORT",
  "HOLDINGS_REPORT",
] as const;
export type DocType = typeof DocumentTypes[number];

export const AvailableSourceOfFunds = [
  "SALARY",
  "BUSINESS_INCOME",
  "PENSION",
  "OTHER",
] as const;

export type SourceOfFunds = typeof AvailableSourceOfFunds[number];

export const TranasactionKinds = ["TRANSFER", "SWAP", "AXIE_BREED"] as const;

export type TransactionKind = typeof TranasactionKinds[number];

export const LoginMethods = [
  "METAMASK",
  "GOOGLE_LOGIN",
  "ADDRESS_ONLY",
] as const;

export type LoginMethod = typeof LoginMethods[number];

export const Tiers = ["STARTER", "PLUS", "ENTERPRISE"] as const;
export const AdminTiers = ["ADMIN", "SUPER_ADMIN", ...Tiers] as const;

export type Tier = typeof Tiers[number];
export type AdminTier = typeof AdminTiers[number];

export const AvailablePaymentNetwork = [
  "ETHEREUM",
  "POLYGON",
  "FANTOM",
  "BINANCE_SMART_CHAIN",
] as const;
export type PaymentNetwork = typeof AvailablePaymentNetwork[number];

// Auth
export type User = {
  address: string;
  full_access?: boolean;
  fiat_currency?: Fiat;
  has_wallet?: boolean;
  subscription: {
    plan: Tier;
    ends_at?: string;
    cancelled_at?: string;
    recently_ended: boolean;
    payment_provider: PaymentProvider;
  };
};

export type LoginWithWalletResponse = {
  primary_address: string;
  fiat_currency: Fiat;
};

export type Challenge = {
  challenge: string;
};

export type GoogleLoginResponse = {
  fiat_currency: string;
  email: string;
  has_wallet: boolean;
};

// Address data
export type Balance = {
  amount: string;
  token: Token;
  value_fiat: string;
};

export type Balances = {
  tokens: Balance[];
  total: {
    value_fiat: string;
  };
  fiat_currency: Fiat;
  user_bal_sync: boolean;
};

export type PortfolioValue = {
  data_points: { measured_on: string; value_fiat: string }[];
  change_fiat: string;
  fiat_currency: Fiat;
  user_bal_sync: boolean;
  premium_user: boolean;
};

type NetChange = {
  net_income_fiat: string;
  net_spending_fiat: string;
};

export type MonthlySummaryToken = NetChange & {
  token: Token;
};

export type MonthlySummary = {
  tokens: MonthlySummaryToken[];
  total: NetChange;
};

export type NetChangeRow = {
  token: Token;
  netChange: string;
};

// Transactions
export type TransactionContact = {
  id: number;
  first_name: string;
  last_name: string;
  company: string;
};

export type TransactionWallet = {
  id: number;
  name: string;
  address: string;
};

export type TransactionCex = {
  id: number;
  exchange: Exchange;
};

export type Transaction = {
  id: number;
  amount: string;
  created_at: string;
  direction: "INCOMING" | "OUTGOING";
  network: Network;
  kind: TransactionKind;
  from_address: string | null;
  to_address: string;
  value_fiat: string;
  fiat_currency: Fiat;
  tx_hash: string;
  contact: TransactionContact | null;
  transc_label: TranscLabel | null;
  labels: any[];
  wallet: TransactionWallet | null;
  cex: TransactionCex | null;
  gas: string;
  gas_price: string;
  fee: string;
  token: Token;
  nft_id: string;
  nft_image_url: string;
  swapped_token: Token | null;
  swapped_amount: string;
  axie_breed_axs_cost: string;
  axie_breed_slp_cost: string;
};

export type Token = {
  id: number;
  name?: string;
  symbol: string;
  image_url: string;
};

// Contacts
export type ContactAddress = {
  network: Network;
  address: string;
};

export type Address = {
  address_line1: string;
  address_line2: string;
  city: string;
  postal_code: string;
  country: string;
  tax_number: string;
};

export type Contact = Address & {
  first_name: string;
  last_name: string;
  company: string;
  labels: any[];
  email: string;
  addresses: ContactAddress[];
};

export type ContactResponse = Contact & {
  id: number;
};

// Lables
export type Label = {
  name: string;
  color: Color;
};

export type LabelResponse = Label & {
  id: number;
};

// PaymentData
export type PaymentData = {
  hash: any;
  data: any;
};

//TranscLabel
export type TranscLabel = {
  name: string;
  tx_hash: string;
  invoice: string;
};

export type TranscLabelResponse = TranscLabel & {
  id: number;
};

// Wallets
export type Wallet = {
  name: string;
  network: Network;
  address: string;
  is_active: boolean;
};

export type WalletResponse = Wallet & {
  id: number;
  total_balance: string;
  wallet_documents: DocumentResponse[];
  last_activity: Transaction[];
  tokens: Token[];
  is_synchronized: boolean;
};

//Centralised Exchange
export type Cex = {
  exchange: Exchange;
  api_key: string;
  secret_key: string;
};

export type CexResponse = {
  id: number;
  exchange: Exchange;
  total_balance: string;
  last_activity: Transaction[];
  tokens: Token[];
};

// Fiat to Crypto
export type FTC = {
  first_name: string;
  last_name: string;
  token_preference: "USDC" | "USDT";
  email: string;
  wallet: number;
  country: string;
};

export type FTCSetupResponse = {
  ftc_session_id?: string;
  user_uuid: string;
  bank_uuid: string | null;
  bank_bic: string | null;
  bank_iban: string | null;
  payment_ref: string;
  token_preference: "USDC" | "USDT";
  network_preference: "ETHEREUM" | "POLYGON";
  wallet: number;
  kyc: KYC;
};

export type FTCKYC = {
  ftc_session_id: string;
  address_line_1: string;
  address_line_2?: string;
  post_code: string;
  city: string;
  country: string;
  date_of_birth: string;
  source_of_funds: SourceOfFunds;
};

export type FTCKYCResponse = {
  token: string;
};

export type FTCLogin = {
  ftc_session_id: string;
};

export type FTCUpdateToken = {
  ftc_session_id: string;
  token: "USDC" | "USDT";
  network: "POLYGON" | "ETHEREUM";
};

//TokenFiat
export type TokenFiatResponse = {
  quote_rate: string;
};

// Documents
export type BnDocument = {
  name: string;
  type: string;
  reportRange: { from: string; to: string };
  created_at: string;
};

export type DocumentObj = {
  kind: DocType;
  name: string;
  end_date: string;
  start_date: string;
  wallets?: number | "All" | number[];
  cex?: number[];
  quickbooks_url?: string;
};

export type DocumentResponse = DocumentObj & {
  id: number;
  created_at: string;
  user_fiat_currency: Fiat;
  invoice: Pick<
    InvoiceResponse,
    | "id"
    | "name"
    | "state"
    | "total_amount"
    | "expires_at"
    | "token"
    | "contact"
    | "value_fiat"
    | "fiat_currency"
  > | null;
};

export type FungibleTokensResponse = {
  token: Token;
  balance: number;
  current_price: number;
  value: number;
  unrealized_gain: number;
};
export type transaction_history = {
  timestamp: string;
  action: {
    id: string;
    final_bid: string;
    currency: string;
    from: string;
    to: string;
  };
};
export type properties = {
  property_id: string;
  name: string;
};

export type NonFungibleTokensData = {
  nft_id: string;
  collection: string;
  token_type: string;
  contract_address: string;
  image_url: string;
  name: string;
  created_at: string | null;
  properties: properties[];
  price: string;
  is_price_synced: boolean;
  currency: Fiat;
  tx_history?: transaction_history[];
};
export type TransactionReportPdfRecord = {
  from_address: string;
  to_address: string;
  amount: string;
  value_fiat: string;
  created_at: string;
  token: Token;
};
export type HoldingsReportPdfRecord = {
  token: Token;
  itin_number: string;
  holdings_at_start: string;
  holdings_at_end: string;
  change: number;
  inflow: number;
  outflow: number;
  wallet: Wallet;
};

// Invoices
export type InvoiceItem = {
  name: string;
  description: string;
  quantity: string;
  unit_price: string;
  discount: string;
  tax: string;
  discount_is_percent: boolean;
  tax_is_percent: boolean;
};

export type InvoiceItemResponse = InvoiceItem & {
  total_amount: string;
};

type InvoiceOwner = {
  first_name: string;
  last_name: string;
  company: string;
  email: string;
  address_line1: string;
  address_line2: string;
  city: string;
  postal_code: string;
  country: string;
  tax_number: string;
  bank_name?: string;
  bank_account?: string;
  clearing_number?: string;
  iban?: string;
  swift?: string;
};

export type Invoice = {
  owner: InvoiceOwner;
  contact: number;
  wallet: number;
  items: InvoiceItem[];
  message?: string;
  token: Token;
};
export type LimitResponse = {
  count: number;
  limit: number;
  can_create: boolean;
};

export type InvoiceResponse = {
  id: string;
  name: string;
  fiat_currency: Fiat;
  kind: InvoiceChoice;
  number?: string;
  vendor?: string;
  project_name?: string;
  covering_period?: string;
  ftc?: FTCSetupResponse;
  owner: InvoiceOwner;
  wallet: WalletResponse;
  contact: ContactResponse;
  message?: string;
  items: InvoiceItemResponse[];
  total_amount: string;
  contract_address: string;
  created_at: string;
  issued_at: string;
  expires_at: string;
  download_only: boolean;
  is_ftc: boolean;
  state: typeof InvoiceTypes[number];
  token: Token;
  value_fiat: string;
  days_to_expire: number;
  total_tax_percentage: number;
  total_tax_amount: string;
  total_actual_amount: string;
  total_discount_amount: string;
  total_base_amount: string;
};

// Ingerations
export type ConnectQuickbooksResponse = {
  auth_url: string;
};

export type IntegrationConnectionStateResponse = {
  state: boolean;
};

// Query Params
export type PaginatedParams = {
  limit: number;
  offset?: number;
};

export type TransactionsParams = PaginatedParams & {
  ordering?: string;
  search?: string;
  created_at_before?: string;
  created_at_after?: string;
  labels?: number[];
  kind?: TransactionKind;
  token?: string;
  token__in?: string;
};

export type DocumentsParams = PaginatedParams & {
  ordering?: string;
  search?: string;
  created_at_before?: string;
  created_at_after?: string;
  kind?: string[];
  state?: string[];
  token?: string;
  aditional_ordering?: string;
};

export type AdminUsersParams = PaginatedParams & {
  ordering?: string;
  created_at_before?: string;
  created_at_after?: string;
  kind__in?: string;
  blockchain__in?: string;
  plan__in?: string;
  is_staff?: boolean;
};

export type WalletsParams = {
  ordering?: string;
  search?: string;
};

export type ContactsParams = {
  ordering?: string;
  search?: string;
};

export type TokenFiatParams = {
  token: string;
  fiat_currency: string;
};

export type NftParams = {
  ordering?: string;
  collection?: string;
};

// Pricing plans
export const PaymentProviders = ["STRIPE"] as const;

export type PaymentProvider = typeof PaymentProviders[number];

export type PricingCardProps = {
  tier?: Tier;
  priceAnnually?: string;
  priceMonthly?: string;
  percentGain?: string;
  btnText: string;
  features: string[];
  period?: "Monthly" | "Annually";
  isCurrent?: boolean;
  bestPlan?: boolean;
  onPlanChoose?: () => void;
};

export type PricingPlanResponseItem = {
  max_num_wallets?: number;
  max_num_invoices?: number;
  max_num_transactions?: number;
  max_num_exchanges?: number;
  monthly_price_usd?: string;
  monthly_price_eur?: string;
  annual_price_usd?: string;
  annual_price_eur?: string;
  monthly_plan_stripe_id?: string;
  annual_plan_stripe_id?: string;
};

export type PricingPlanResponse = {
  [plan in Tier]: PricingPlanResponseItem;
};

export type SelectedPlan = PricingPlanResponseItem & {
  tier: Tier;
};

// Admin
export type AdminUser = {
  id: number;
  created_at: string;
  email?: string;
  kind: string;
  primary_address: string;
  plan: AdminTier;
  is_superadmin: boolean;
  wallets: Pick<Wallet, "address" | "network">[];
};

export type TotalWalletsResponse = {
  [network in Network]: number;
};

export type FTCAnalyticsResponse = {
  num_kyc_full_users: number;
  num_ftc_invoices: number;
  completed_payment_volume: string;
  outstanding_payment_volume: string;
  num_kyc_full_users_diff_pct?: number;
  num_ftc_invoices_diff_pct?: number;
  completed_payment_volume_diff_pct?: number;
  outstanding_payment_volume_diff_pct?: number;
};

// Forms props
export type AdminUsersSectionFilterFormProps = {
  start_date: string;
  end_date: string;
  isAdminOnly: boolean;
  selectedNetworks: Network[];
  selectedLoginMethods: LoginMethod[];
  selectedPlans: AdminTier[];
};

// Others
export type Paginated<T> = {
  count: number;
  previous: string;
  next: string;
  results: T[];
  user_tx_sync?: boolean;
};

export type Action = {
  icon: string;
  contentText: string;
  clickAction?: any;
  Component?: React.ReactElement;
};

export type SetUseState<T> = React.Dispatch<React.SetStateAction<T>>;

export type DropdownOption<LT = string, VT = any> = {
  key?: string;
  label: LT;
  value: VT;
};

export type TimePeriod = {
  start_date: Date;
  end_date: Date;
};

export type SearchResultList = {
  id: number;
  text: string;
};

export type SearchResults = Record<string, SearchResultList[]>;

export type LocationProps = {
  search?: string;
  invoice?: InvoiceResponse;
  tutorial?: string;
};

export type DateFilter = {
  start_date: string | Date;
  end_date: string | Date;
};
export type CurrencyFilter = {
  currency: string;
};

export type ReportTypeFilter = {
  types: string[];
};

export type FungibleTokensDataType = {
  asset_name: string;
  asset_symbol: string;
  balance: number;
  current_price: number;
  value: number;
  unrealized_gain: number;
};
export type EditableUser = {
  first_name?: string;
  last_name?: string;
  address_line1?: string;
  address_line2?: string;
  city?: string;
  postal_code?: string;
  country?: string;
  tax_number?: string;
  fiat_currency?: Fiat;
  is_tutorial_viewed?: boolean;
  language?: string;
  timezone?: string;
  email?: string;
};

export type FaqElem = {
  question: string;
  answer: string;
};

export type AnalyticsType = {
  num_new_users_last_30_days: number;
  new_users_diff_pct: number;
  num_active_users_last_30_days: number;
  active_users_diff_pct: number;
  num_premium_users: number;
  premium_users_diff_pct: number;
};

export type UserGrowthHistory = {
  measured_on: string;
  num_users: number;
}[];

export type NetworkChainData = {
  [network in Network]?: {
    chainId: string;
    chainName?: string;
    nativeCurrency?: {
      name: string;
      symbol: string;
      decimals: 18;
    };
    rpcUrls?: string[];
    blockExplorerUrls?: string[];
  };
};

export type SuggestionProps = {
  header: string;
  body: string;
  onClose: () => void;
  onClick: () => void;
  isVisible: boolean;
  extraClass?: string;
};
